<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8">
        <c-card title="Crear Herramienta">
          <tool-form />
        </c-card>
      </div>
    </div>
  </div>
</template>

<script>
import CCard from "@/components/CCard.vue";
import ToolForm from "@/components/MarcoLegalGeneral/ToolForm.vue";

export default {
  components: {
    CCard,
    ToolForm,
  },
};
</script>
